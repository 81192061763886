/* eslint-disable react/no-unknown-property */
import homeMobileImg from "assets/images/home-mobile.avif";
import leftBaner from "assets/images/left-banner.png";
import rightBaner from "assets/images/right-banner.png";
import {
	BannerAppDownload,
	BenefitsCarousel,
	Button,
	DownloadMenu,
	FixedButton,
	Loading,
	LocationsCarousel,
	PartnerAdvantage,
	StepsCarousel,
	WrapperAccordion
} from "components";
import CookieBar from "components/CookieBar";
import { ErrorHelper } from "helpers";
import { Benefit as BenefitType } from "models/types";
import { Suspense, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { BenefitService } from "services/benefit";
import { faq } from "./faq";
import "./styles.scss";

export function LoadingSpinner() {
	return (
		<div className="p-12 h-[500px] flex flex-row justify-center">
			<Loading type="primary" />
		</div>
	);
}

export default function Home() {
	const activeArea = useRef<HTMLDivElement>(null);
	const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });
	const bodyRef = useRef<HTMLDivElement>(null);
	const [isBodyVisible, setIsBodyVisible] = useState<boolean>(true);
	const [downloadMenuActive, setDownloadMenuActive] = useState<boolean>(false);
	const [benefits, setBenefits] = useState<BenefitType[]>();
	const benefitService = new BenefitService();

	const setVisibility = (entries: IntersectionObserverEntry[]) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const [entry] = entries;
		// setIsBodyVisible(entry.isIntersecting);
		setIsBodyVisible(true);
	};

	const visibilityOptions = {
		root: null,
		rootMargin: "0px",
		threshold: 0.5
	};

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setIsBodyVisible(true);
		}, 1000);

		return () => clearTimeout(timeoutId);
	}, []);

	useEffect(() => {
		const observer = new IntersectionObserver(setVisibility, visibilityOptions);
		if (!isBodyVisible) {
			if (bodyRef.current) observer.observe(bodyRef.current);
		}

		return () => {
			if (bodyRef.current) observer.unobserve(bodyRef.current);
		};
	}, [bodyRef, visibilityOptions]);

	useEffect(() => {
		if (isBodyVisible) {
			benefitService
				.listHighlightedBenefits()
				.then((response) => {
					setBenefits(response.content);
				})
				.catch((error) => {
					toast.error(ErrorHelper.getErrorMessage(error));
				});
		}
	}, [isBodyVisible]);

	const handleDownloadMenuToggle = () => {
		if (!downloadMenuActive) {
			document.body.style.overflowY = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
		setDownloadMenuActive(!downloadMenuActive);
	};

	return (
		<>
			<Helmet>
				<title>Vai na Boa – Benefícios Exclusivos CCR ViaLagos</title>
				<meta
					name="description"
					content="Descubra os benefícios exclusivos do programa Vai na Boa da CCR ViaLagos. Ofertas especiais e descontos para você aproveitar na sua viagem!"
				/>
				<meta
					name="keywords"
					content="CCR ViaLagos, benefícios CCR, descontos viagem, programa de fidelidade"
				/>
			</Helmet>
			<CookieBar />
			<div className="home">
				<div className="page-header">
					<div className="wrapper-general title">
						<h1>Programa de Benefícios</h1>
						<h1 className="highlight">Vai na Boa</h1>
					</div>
					<div className="banner">
						<div className="banner-image">
							{isMobile ? (
								<img
									fetchpriority="high"
									src={homeMobileImg}
									alt="Logotipo roxo do programa Vai na Boa do grupo CCR em um fundo cinza claro."
								/>
							) : (
								<div className="image-row">
									<img
										fetchpriority="high"
										src={leftBaner}
										alt="Logotipo roxo do programa Vai na Boa do grupo CCR."
									/>
									<img
										fetchpriority="high"
										src={rightBaner}
										alt="Mulher sorridente segurando um smartphone contra um fundo claro com elementos roxos, representando o programa Vai na Boa."
									/>
								</div>
							)}
						</div>
						<div className="action-card">
							<h5>
								Quem vai com a CCR ViaLagos, transforma viagens em vantagens.
							</h5>
							<p>
								Baixe o app e aproveite os descontos e benefícios com nossos
								parceiros na Costa do Sol.
							</p>
							{isMobile ? (
								<Link to="download">
									<Button size="medium">Baixar aplicativo</Button>
								</Link>
							) : (
								<Button size="medium" onClick={handleDownloadMenuToggle}>
									Baixar aplicativo
								</Button>
							)}
						</div>
					</div>
				</div>
				<Suspense fallback={<LoadingSpinner />}>
					<div ref={bodyRef}>
						{!isBodyVisible ? (
							<div className="p-12 h-[500px] flex flex-row justify-center">
								<Loading type="primary" />
							</div>
						) : (
							<>
								<BenefitsCarousel benefits={benefits} />
								{!isMobile && (
									<BannerAppDownload onClick={handleDownloadMenuToggle} />
								)}
								<div ref={activeArea}>
									<StepsCarousel />
									<LocationsCarousel />
									<PartnerAdvantage />
									{!isMobile && (
										<BannerAppDownload onClick={handleDownloadMenuToggle} />
									)}
									<WrapperAccordion title={faq.title} data={faq.data} />
								</div>
								{isMobile && (
									<Link to="download">
										<FixedButton activeAreaRef={activeArea}>
											Baixar aplicativo
										</FixedButton>
									</Link>
								)}
								<DownloadMenu
									isVisible={downloadMenuActive}
									onClose={handleDownloadMenuToggle}
								/>
							</>
						)}
					</div>
				</Suspense>
			</div>
		</>
	);
}
